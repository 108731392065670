.form {
    background-image: url(../img/texture.jpg);
    height: 290px;
    -webkit-box-shadow: 0px -7px 16px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 0px -7px 16px 0px rgba(0,0,0,0.39);
    box-shadow: 0px -7px 16px 0px rgba(0,0,0,0.39);
    position: relative;
    @include mediaw(576px) {
        height: initial;
    }
    &::before {
        content: " ";
        display: block;
        width: 1284px;
        height: 140px;
        position: absolute;
        top: -140px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../img/form-bg.png);
        background-size: cover;
        @include mediaw(768px) {
            top: -105px;
        }
        @include mediaw(768px) {
            top: -85px;
        }
    }
    &__overlay {
        max-width: 930px;
        margin: 0 auto;
        position: relative;
        padding: 0 15px;
        &::before {
            content: " ";
            display: block;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            position: absolute;
            top: -95px;
            right: -140px;
            background-color: $colorPurple;
            background-repeat: no-repeat;
            background-image: url(../img/pen.png);
            background-position: center center;
            @include mediaw(1199px) {
                display: none;
            }
        }
    }
    &__content {
        position: relative;
        top: -35px;
    }
    &__title {
        font-family: $fontBlissHeavy;
        font-size: 30px;
        color: $colorPurple;
        text-align: center;
        text-transform: uppercase;
        @include mediaw(480px) {
            font-size: 24px;
        }
    }
    ul {
        width: 440px;
        margin: 15px auto 0;
        font-family: $fontBlissLight;
        font-size: 21px;
        line-height: 30px;
        color: #333333;
        @include mediaw(480px) {
            width: 100%;
            font-size: 17px;
            line-height: 21px;
        }
        li {
            background-repeat: no-repeat;
            background-image: url(../img/points.png);
            background-position: left center;
            padding-left: 45px;
            @include mediaw(480px) {
                padding-left: 27px;
                background-size: 20px;
            }
            &:not(:first-child) {
                margin-top: 10px;
            }
        }
    }
    form {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        @include mediaw(576px) {
            flex-wrap: wrap;
        }
        input {
            display: block;
            width: calc(33.33334% - 10px);
            height: 67px;
            border-radius: 33.5px;
            font-size: 20px;
            line-height: 24px;
            @include mediaw(576px) {
                width: 300px;
                max-width: 100%;
                margin: 0 auto;
            }
            @include mediaw(480px)  {
                height: 50px;
                font-size: 17px;
            }
            &:not(:first-child) {
                @include mediaw(576px) {
                    margin: 15px auto 0;
                }
            }
            &:not([type="submit"]) {
                text-align: center;
                border: 1px solid #9b9b9b;
                font-family: $fontBlissLight;
            }
            &[type="submit"] {
                background-color: $colorGreen;
                color: #fff;
                transition: background-color .15s linear;
                cursor: pointer;
                font-family: $fontBlissExtraBold;
                &:hover {
                    background-color: #04e080;
                }
            }
        }
    }
}

.advantages {
    padding: 60px 0;
    background-image: url(../img/advantages-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: $fontBlissBold;
    font-size: 24px;
    color: #fff;
    @include mediaw(480px) {
        padding: 45px 0;
        font-size: 20px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
    }
    &__col {
        width: calc(25% - 15px);
        text-align: center;
        @include mediaw(992px) {
            width: calc(50% - 15px);
        }
        @include mediaw(480px) {
            width: 100%;
        }
        &:nth-child(3),
        &:nth-child(4) {
            @include mediaw(992px) {
                margin-top: 40px;
            }
        }
        &:not(:first-child) {
            @include mediaw(480px) {
                margin-top: 35px;
            }
        }
    }
    &__image-block {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mediaw(480px) {
            height: 150px;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
            @include mediaw(480px) {
                max-height: 100%;
            }
        }
    }
    p {
        margin-top: 25px;
    }
}

.choose {
    padding: 55px 0 100px;
    &__buttons {
        height: 75px;
        border-radius: 37.5px;
        border: 1px solid $colorPurple;
        overflow: hidden;
        display: flex;
        margin-top: 50px;
        @include mediaw(480px) {
            height: 50px;
            border-radius: 25px;
            margin-top: 25px;
        }
        button {
            display: block;
            width: 33.33334%;
            background-color: #f3f5f8;
            font-size: 24px;
            font-family: $fontBlissRegular;
            cursor: pointer;
            @include mediaw(768px) {
                font-size: 18px;
            }
            @include mediaw(480px) {
                font-size: 13px;
            }
            &.active {
                background-color: $colorPurple;
                font-family: $fontBlissExtraBold;
                color: #fff;
            }
            &:nth-child(2) {
                border-left: 1px solid $colorPurple;
                border-right: 1px solid $colorPurple;
            }
        }
    }
    &__row {
        justify-content: space-between;
        display: none;
        @include mediaw(1199px) {
            flex-wrap: wrap;
        }
        &.active {
            display: flex;
        }
    }
    &__col {
        width: 33.33334%;
        padding: 0 33px;
        text-align: center;
        @include mediaw(1199px) {
            width: 50%;
        }
        @include mediaw(768px) {
            width: 100%;
            padding: 0;
        }
        .choose__title {
            margin-top: 55px;
            font-family: $fontBlissExtraBold;
            font-size: 30px;
            color: $colorPurple;
            @include mediaw(480px) {
                margin-top: 35px;
                font-size: 25px;
            }
        }
        .image-block {
            margin-top: 40px;
            position: relative;
            @include mediaw(480px) {
                margin-top: 20px;
            }
            img {
                display: block;
                margin: 0 auto;
                width: 300px;
                height: 300px;
                border-radius: 50%;
                object-fit: cover;
                @include mediaw(348px) {
                    width: 280px;
                    height: 280px;
                }
            }
            .characteristics {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 140px;
                height: 40px;
                text-align: center;
                border: 1px solid #c2c2c2;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $fontBlissExtraLight;
                font-size: 16px;
                color: #333333;
                cursor: pointer;
                @include mediaw(576px) {
                    right: 10px;
                }
                &::after {
                    content: " ";
                    width: 56px;
                    height: 54px;
                    background-repeat: no-repeat;
                    background-image: url(../img/characteristics.png);
                    background-size: cover;
                    position: absolute;
                    top: -40px;
                    right: -25px;
                }
            }
        }
        .garancy {
            .row {
                display: flex;
                &:first-child {
                    margin-top: 25px;
                    background-color: #f3f5f8;
                    font-family: $fontBlissExtraBold;
                    font-size: 17px;
                    color: #333;
                    padding: 10px 0;
                    @include mediaw(480px) {
                        font-size: 16px;
                    }
                    .left {
                        position: relative;
                        padding-left: 15px;
                        &::after {
                            content: " ";
                            display: block;
                            height: 26px;
                            width: 1px;
                            background-color: $colorPurple;
                            position: absolute;
                            right: 0.5px;
                            top: calc(50% - 13px);
                        }
                    }
                    .right {
                        padding-right: 10px;
                    }
                }
                &:nth-child(2) {
                    margin-top: 5px;
                    font-family: $fontBlissHeavy;
                    font-size: 24px;
                    color: $colorPurple;
                    padding: 0 20px;
                    @include mediaw(480px) {
                        font-size: 21px;
                    }
                }
                .left {
                    width: 35%;
                    text-align: left;
                }
                .right {
                    width: 65%;
                    text-align: right;
                }
            }
        }
        .choose__description {
            margin-top: 25px;
            font-family: $fontBlissExtraLight;
            font-size: 21px;
            line-height: 24px;
            color: #333;
            text-align: left;
            padding: 0 10px;
            @include mediaw(480px) {
                font-size: 17px;
                line-height: 20px;
            }
        }
        .modal {
            text-transform: uppercase;
            margin: 20px auto 0;
            width: 277px;
            height: 65px;
            border-radius: 32.5px;
            font-family: $fontBlissExtraBold;
            font-size: 20px;
            line-height: 24px;
            @include mediaw(480px) {
                height: 50px;
                border-radius: 25px;
                font-size: 17px;
                margin: 20px auto 0;
            }
        }
    }
}

.how {
    padding: 55px 0 60px;
    background-image: url(../img/how-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include mediaw(480px) {
        padding: 40px 0 40px;
    }
    &::before {
        @include mediaw(1500px) {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.7);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .container {
        position: relative;
    }
    .steps {
        width: 380px;
        margin-left: auto;
        margin-right: 76px;
        font-family: $fontBlissLight;
        font-size: 21px;
        line-height: 24px;
        color: #333;
        @include mediaw(1199px) {
            margin: 25px auto 0;
            transform: translateX(95px);
        }
        @include mediaw(768px) {
            width: calc(100% - 155px);
        }
        @include mediaw(576px) {
            width: calc(100% - 96px);
            margin-left: 0;
            margin-right: auto;
        }
        @include mediaw(480px) {
            transform: translateX(0);
            margin: 25px 0 0;
            width: 100%;
        }
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:not(:first-child) {
                margin-top: 20px;
            }
            span {
                display: block;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: $colorPurple;
                margin-right: 25px;
                position: relative;
                background-repeat: no-repeat;
                background-position: center center;
                @include mediaw(576px) {
                    width: 75px;
                    height: 75px;
                    background-size: 35px;
                    margin-right: 0;
                }
                &::before {
                    content: " ";
                    display: block;
                    width: 116px;
                    height: 60px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -150px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @include mediaw(576px) {
                        width: 80px;
                        height: 40px;
                        left: -110px;
                    }
                    @include mediaw(480px) {
                        display: none;
                    }
                }
                &::after {
                    content: " ";
                    display: block;
                    width: 32px;
                    height: 2px;
                    background-color: $colorPurple;
                    position: absolute;
                    left: -32px;
                    top: calc(50% - 1px);
                    @include mediaw(576px) {
                        width: 25px;
                        left: -25px;
                    }
                    @include mediaw(480px) {
                        display: none;
                    }
                }
            }
            &:first-child {
                span {
                    background-image: url(../img/mail.png);
                    &::before {
                        background-image: url(../img/01.png);
                    }
                }
            }
            &:nth-child(2) {
                span {
                    background-image: url(../img/engineer.png);
                    &::before {
                        background-image: url(../img/02.png);
                        width: 116px;
                        left: -160px;
                        @include mediaw(576px) {
                            width: 80px;
                            height: 40px;
                            left: -118px;
                        }
                    }
                }
            }
            &:nth-child(3) {
                span {
                    background-image: url(../img/pen.png);
                    &::before {
                        background-image: url(../img/03.png);
                        width: 116px;
                        left: -160px;
                        @include mediaw(576px) {
                            width: 80px;
                            height: 40px;
                            left: -118px;
                        }
                    }
                }
            }
            &:nth-child(4) {
                span {
                    background-image: url(../img/open-window.png);
                    &::before {
                        background-image: url(../img/04.png);
                        width: 116px;
                        left: -160px;
                        @include mediaw(576px) {
                            width: 80px;
                            height: 40px;
                            left: -118px;
                        }
                    }
                }
            }
            p {
                width: calc(100% - 125px);
                @include mediaw(576px) {
                    width: calc(100% - 85px);
                }
            }
        }
    }
    .modal {
        display: block;
        margin: 60px auto 0;
        width: 277px;
        height: 65px;
        border-radius: 32.5px;
        font-family: $fontBlissExtraBold;
        font-size: 20px;
        line-height: 24px;
        @include mediaw(480px) {
            font-size: 17px;
            height: 50px;
            margin: 40px auto 0;
        }
    }
}

.best-works {
    padding: 60px 0;
    @include mediaw(480px) {
        padding: 40px 0;
    }
    .best__overlay {
        width: 1545px;
        margin: 60px auto 0;
        @include mediaw(1580px) {
            width: 100%;
        }
        @include mediaw(480px) {
            margin: 30px auto 0;
        }
    }
    .best__slider {
        a {
            display: block;
            width: 505px;
            height: 345px;
            object-fit: cover;
            margin: 0 5px; 
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-dots {
            bottom: -54px;
            z-index: 1;
            li {
                width: 10px;
                height: 10px;
                background-color: rgba(70, 0, 150, 0.5);
                &.slick-active {
                    background-color: rgba(70, 0, 150, 1);
                }
                button {
                    opacity: 0;
                }
            }
        }
    }
    .best__btns {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: -2px;
        cursor: pointer;
        @include mediaw(1580px) {
            padding: 0 5px;
        }
        .best__prev,
        .best__next {
            width: 23px;
            height: 33px;
            background-size: cover;
            z-index: 2;
        }
        .best__prev {
            background-image: url(../img/arrow-left.png);
        }
        .best__next {
            background-image: url(../img/arrow-right.png);
        }
    }
}

.reviews {
    padding: 0 0 50px;
    &__row {
        display: flex;
        justify-content: space-between;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
    }
    &__col {
        width: calc(50% - 10px);
        @include mediaw(992px) {
            width: 100%;
        }
    }
    &__item {
        display: flex;
        margin-top: 20px;
        padding: 27px 20px 35px 23px;
        background-color: #f3f5f8;
        @include mediaw(480px) {
            padding: 30px 10px 10px 10px;
            flex-wrap: wrap;
            justify-content: center;
        }
        &-image-block {
            position: relative;
            width: 140px;
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
            .quote {
                display: block;
                width: 70px;
                height: 67px;
                background-image: url(../img/quote.png);
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                right: -30px;
                top: -20px;
            }
        }
        .reviews__block {
            margin-left: 33px;
            color: #333;
            font-size: 20px;
            line-height: 24px;
            width: calc(100% - 173px);
            @include mediaw(480px) {
                margin-top: 15px;
                width: 100%;
                margin-left: 0;
                font-size: 17px;
                line-height: 22px;
            }
            .text {
                font-family: $fontBlissExtraLight;
            }
            .author {
                margin-top: 7px;
                font-family: $fontBlissExtraBold;
                text-align: right;
            }
        }
    }
}

.promo {
    height: 690px;
    background-image: url(../img/promo-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    @include mediaw(480px) {
        height: 400px;
    }
    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    p {
        &:first-child {
            font-family: $fontBlissLight;
            font-size: 42px;
            line-height: 66px;
            @include mediaw(768px) {
                font-size: 34px;
            }
            @include mediaw(480px) {
                font-size: 21px;
                line-height: 30px;
            }
        }
        &:nth-child(2) {
            margin-top: 15px;
            text-transform: uppercase;
            display: inline-block;
            padding: 0 15px;
            background-color: $colorPurple;
            font-family: $fontBlissHeavy;
            font-size: 60px;
            line-height: 90px;
            @include mediaw(768px) {
                font-size: 38px;
            }
            @include mediaw(480px) {
                font-size: 23px;
                line-height: 40px;
            }
        }
        &:nth-child(3) {
            text-transform: uppercase;
            font-size: 42px;
            line-height: 72px;
            font-family: $fontBlissHeavy;
            @include mediaw(480px) {
                font-size: 30px;
                line-height: 50px;
            }
        }
        &:nth-child(4) {
            font-family: $fontBlissLight;
            font-size: 30px;
            line-height: 66px;
            @include mediaw(480px) {
                font-size: 24px;
                line-height: 40px;
            }
            span {
                text-transform: uppercase;
                font-family: $fontBlissHeavy;
                padding: 10px;
                background-color: $colorPurple;
            }
        }
    }
}

.security {
    padding: 50px 0;
    &__row {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
    }
    &__col {
        width: calc(25% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        @include mediaw(992px) {
            width: calc(50% - 10px);
        }
        &:nth-child(3),
        &:nth-child(4) {
            @include mediaw(992px) {
                margin-top: 25px;
            }
        }
        img {
            display: block;
            max-width: 100%;
        }
    }
    .certificate__slider {
        .slick-track {
            @include mediaw(1199px) {
                margin: 0 -330px;
            }
            @include mediaw(992px) {
                margin: 0 -340px;
            }
            @include mediaw(768px) {
                margin: 0 -350px;
            }
            @include mediaw(576px) {
                // margin: initial;
            }
        }
        .certificate__slide {
            display: block;
            width: 320px;
            height: 465px;
            margin: 20px 30px;
            -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
            @include mediaw(1199px) {
                margin: 20px 55px;
            }
            @include mediaw(992px) {
                margin: 20px 15px;
            }
            @include mediaw(576px) {
                // margin: 20px 0;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-dots {
            bottom: -54px;
            li {
                width: 10px;
                height: 10px;
                background-color: rgba(70, 0, 150, 0.5);
                &.slick-active {
                    background-color: rgba(70, 0, 150, 1);
                }
                button {
                    opacity: 0;
                }
            }
        }
    }
    .certificate__btns {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: -2px;
        z-index: -1;
        cursor: pointer;
        .certificate__prev,
        .certificate__next {
            width: 23px;
            height: 33px;
            background-size: cover;
        }
        .certificate__prev {
            background-image: url(../img/arrow-left.png);
        }
        .certificate__next {
            background-image: url(../img/arrow-right.png);
        }
    }
}

.workers {
    padding: 95px 0;
    @include mediaw(480px) {
        padding: 60px;
    }
    &__row {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
        @include mediaw(480px) {
            margin-top: 40px;
        }
    }
    &__col {
        width: calc(25% - 10px);
        text-align: center;
        @include mediaw(992px) {
            width: calc(50% - 10px);
        }
        @include mediaw(480px) {
            width: 100%;
        }
        &:nth-child(2) {
            @include mediaw(480px) {
                margin-top: 35px;
            }
        }
        &:nth-child(3),
        &:nth-child(4) {
            @include mediaw(992px) {
                margin-top: 35px;
            }
        }
        .name {
            text-transform: uppercase;
            font-family: $fontBlissHeavy;
            font-size: 30px;
            line-height: 45px;
            margin-top: 15px;
            color: $colorPurple;
            @include mediaw(480px) {
                font-size: 23px;
                line-height: 30px;
                margin-top: 10px;
            }
        }
        .position {
            font-family: $fontBlissExtraLight;
            font-size: 20px;
            line-height: 27px;
            color: #333;
            @include mediaw(480px) {
                font-size: 17px;
                line-height: 24px;
            }
        }
    }
    &__image-block {
        width: 196px;
        height: 196px;
        margin: 0 auto;
        img {
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        position: relative;
    }
    &__call {
        position: absolute;
        bottom: 0;
        right: 9px;
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $colorGreen;
        background-image: url(../img/phone.png);
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.contact {
    background-image: url(../img/texture.jpg);
    position: relative;
    -webkit-box-shadow: 0px 7px 16px 0px rgba(0,0,0,0.39);
    -moz-box-shadow: 0px 7px 16px 0px rgba(0,0,0,0.39);
    box-shadow: 0px 7px 16px 0px rgba(0,0,0,0.39);
    &::after {
        content: " ";
        display: block;
        width: 1284px;
        height: 159px;
        position: absolute;
        bottom: -159px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../img/contact-bg.png);
        background-size: cover;
        @include mediaw(480px) {
            bottom: -105px;
        }
    }
    &__overlay {
        max-width: 875px;
        margin: 0 auto;
        position: relative;
        bottom: -35px;
        z-index: 1;
        padding: 0 15px;
    }
    ul {
        display: flex;
        justify-content: space-between;
        max-width: 610px;
        margin: 35px auto 0;
        li {
            a {
                display: block;
                img {
                    display: block;
                }
            }
        }
    }
    &__row {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        font-family: $fontBlissHeavy;
        font-size: 24px;
        line-height: 30px;
        color: #333;
        @include mediaw(768px) {
            flex-wrap: wrap;
        }
        @include mediaw(480px) {
            font-size: 21px;
            line-height: 27px;
        }
    }
    &__col {
        @include mediaw(768px) {
            width: 100%;
            text-align: center;
        }
        &:first-child {
            a {
                display: block;
                &:nth-child(2) {
                    margin-top: 35px;
                }
            }
        }
        &:last-child {
            a {
                display: block;
                margin-bottom: 35px;
            }
        }
    }
}

.map {
    iframe {
        display: block;
        width: 100%;
        height: 500px;
    }
}

.footer {
    background-color: #222222;
    color: #fff;
    text-align: center;
    padding: 50px 0 60px;
    @include mediaw(480px) {
        padding: 35px 0 40px;
    }
    p {
        &:first-child {
            font-family: $fontBlissHeavy;
            font-size: 24px;
            @include mediaw(480px) {
                font-size: 20px;
            }
        }
        &:nth-child(2) {
            font-family: $fontBlissLight;
            font-size: 20px;
            @include mediaw(480px) {
                font-size: 17px;
            }
            a {
                color: $colorGreen;
            }
        }
    }
}

.modal {
    &-content {
        background-color: #fff;
        padding: 15px;
        font-family: $fontBlissLight;
        @include mediaw(480px) {
            padding: 10px;
        }
    }
    &-overlay {
        background-color: rgba(70, 0, 150, 0.9);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
    }
    &-title,
    &-description {
        color: #fff !important;
    }
    &-block {
        width: 650px;
        max-width: 100%;
        padding: 15px;
        position: relative;
        @include mediaw(768px) {
            width: 100%;
            padding: 15px 30px;
        }
        @include mediaw(480px) {
            padding: 10px;
        }
        .modal-close {
            position: absolute;
            top: -26px;
            right: -27px;
            cursor: pointer;
            width: 27px;
            height: 26px;
            background-size: cover;
            background-image: url(../img/close.png);
            @include mediaw(768px) {
                top: 0;
                right: 0;
            }
            @include mediaw(480px) {
                top: -20px;
                right: 10px;
            }
        }
        form {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            @include mediaw(576px) {
                flex-wrap: wrap;
            }
            input {
                display: block;
                width: calc(33.33334% - 10px);
                height: 67px;
                border-radius: 33.5px;
                font-size: 20px;
                line-height: 24px;
                color: #fff;
                @include mediaw(576px) {
                    width: 300px;
                    max-width: 100%;
                    margin: 0 auto;
                }
                @include mediaw(480px)  {
                    height: 50px;
                    font-size: 17px;
                }
                &:not(:first-child) {
                    @include mediaw(576px) {
                        margin: 15px auto 0;
                    }
                }
                &:not([type="submit"]) {
                    text-align: center;
                    border: 1px solid #fff;
                    font-family: $fontBlissLight;
                }
                &[type="submit"] {
                    width: 300px;
                    background-color: $colorGreen;
                    color: #fff;
                    transition: background-color .15s linear;
                    cursor: pointer;
                    font-family: $fontBlissExtraBold;
                    @include mediaw(463px) {
                        width: 220px;
                    }
                    &:hover {
                        background-color: #04e080;
                    }
                }
            }
        }
    }
}

.swal-button {
    background-color: $colorGreen;
}
.swal-button:not([disabled]):hover {
    background-color: #04e080;
}
.swal-title {
    font-family: $fontBlissHeavy;
}
.swal-text {
    font-family: $fontBlissExtraLight;
}