*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

input, button {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: transparent; }
  input:focus, button:focus {
    outline: none; }

body.overflow-active {
  overflow: hidden; }

@font-face {
  font-family: 'Bliss Pro Regular';
  src: url("../fonts/BlissPro.eot");
  src: url("../fonts/BlissPro.eot?#iefix") format("embedded-opentype"), url("../fonts/BlissPro.woff") format("woff"), url("../fonts/BlissPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bliss Pro Black';
  src: url("../fonts/BlissPro-Heavy.eot");
  src: url("../fonts/BlissPro-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/BlissPro-Heavy.woff") format("woff"), url("../fonts/BlissPro-Heavy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy Light';
  src: url("../fonts/Gilroy-Light.eot");
  src: url("../fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Light.woff") format("woff"), url("../fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Play Regular';
  src: url("../fonts/Play.eot");
  src: url("../fonts/Play.eot?#iefix") format("embedded-opentype"), url("../fonts/Play.woff") format("woff"), url("../fonts/Play.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bliss Pro Medium';
  src: url("../fonts/BlissPro-Medium.eot");
  src: url("../fonts/BlissPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/BlissPro-Medium.woff") format("woff"), url("../fonts/BlissPro-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bliss Pro Light';
  src: url("../fonts/BlissPro-Light.eot");
  src: url("../fonts/BlissPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/BlissPro-Light.woff") format("woff"), url("../fonts/BlissPro-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bliss Pro Extra Bold';
  src: url("../fonts/BlissPro-ExtraBold.eot");
  src: url("../fonts/BlissPro-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/BlissPro-ExtraBold.woff") format("woff"), url("../fonts/BlissPro-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bliss Pro Bold';
  src: url("../fonts/BlissPro-Bold.eot");
  src: url("../fonts/BlissPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/BlissPro-Bold.woff") format("woff"), url("../fonts/BlissPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bliss Pro Extra Light';
  src: url("../fonts/BlissPro-ExtraLight.eot");
  src: url("../fonts/BlissPro-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/BlissPro-ExtraLight.woff") format("woff"), url("../fonts/BlissPro-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.header {
  background-image: url(../img/header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 825px; }
  @media only screen and (max-width: 550px) {
    .header {
      min-height: 660px; } }
  .header .top {
    background-color: #460096;
    color: #fff;
    padding: 10px 0;
    font-family: "Bliss Pro Medium";
    font-size: 16px; }
    @media only screen and (max-width: 480px) {
      .header .top {
        padding: 5px 0; } }
    .header .top .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .header .top .lang a {
      display: inline-block; }
      .header .top .lang a:first-child {
        margin-right: 10px; }
      .header .top .lang a.active {
        color: #999999; }
    .header .top ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .header .top ul li:not(:last-child) {
        margin-right: 50px; }
    .header .top .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    @media only screen and (max-width: 992px) {
      .header .top .menu {
        display: none; } }
    .header .top .mobile-social {
      display: none; }
      @media only screen and (max-width: 992px) {
        .header .top .mobile-social {
          display: block; } }
      .header .top .mobile-social ul li a {
        display: block;
        max-width: 40px; }
        .header .top .mobile-social ul li a img {
          display: block;
          max-width: 100%; }
      .header .top .mobile-social ul li:not(:last-child) {
        margin-right: 20px; }
  .header .bottom {
    background-color: rgba(236, 230, 242, 0.5);
    padding: 13px 0; }
    .header .bottom .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media only screen and (max-width: 992px) {
        .header .bottom .container {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          width: 100%;
          padding: 0;
          max-width: 100%; } }
    .header .bottom .left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .header .bottom .logo {
      margin-right: 70px; }
      @media only screen and (max-width: 992px) {
        .header .bottom .logo {
          width: 100%; } }
      .header .bottom .logo a {
        display: block; }
        .header .bottom .logo a img {
          margin: 0 auto; }
    .header .bottom .social a:first-child {
      margin-right: 5px; }
    @media only screen and (max-width: 992px) {
      .header .bottom .social {
        display: none; } }
    .header .bottom .right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media only screen and (max-width: 992px) {
        .header .bottom .right {
          margin-top: 15px;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          width: 100%; } }
    .header .bottom .consultation {
      font-family: "Bliss Pro Light";
      font-size: 16px;
      color: #000002;
      padding-left: 65px;
      background-image: url(../img/headset.png);
      background-repeat: no-repeat;
      background-position: left center; }
      @media only screen and (max-width: 992px) {
        .header .bottom .consultation {
          background: none;
          padding-left: 15px; } }
      @media only screen and (max-width: 525px) {
        .header .bottom .consultation {
          font-size: 12px; } }
      @media only screen and (max-width: 340px) {
        .header .bottom .consultation {
          -webkit-transform: translateX(-11px);
              -ms-transform: translateX(-11px);
                  transform: translateX(-11px); } }
      .header .bottom .consultation a {
        font-family: "Bliss Pro Extra Bold";
        font-size: 24px; }
        @media only screen and (max-width: 525px) {
          .header .bottom .consultation a {
            font-size: 17px; } }
    @media only screen and (max-width: 992px) {
      .header .bottom .modal-wrapper {
        width: 50%;
        border-radius: 0;
        position: relative; } }
    @media only screen and (max-width: 992px) {
      .header .bottom .modal-wrapper a {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-image: url(../img/ringer-white.png);
        background-repeat: no-repeat;
        background-size: 40px;
        background-position: center center;
        position: absolute;
        left: -40px;
        top: calc(50% + -40px);
        background-color: #0dc877; } }
  @media only screen and (max-width: 992px) and (max-width: 525px) {
    .header .bottom .modal-wrapper a {
      width: 56px;
      height: 56px;
      left: -28px;
      top: calc(50% + -28px); } }
    @media only screen and (max-width: 525px) {
      .header .bottom .modal-wrapper a {
        left: -28px;
        top: calc(50% + -28px); } }
    .header .bottom .modal {
      margin-left: 20px;
      width: 217px;
      height: 60px;
      border-radius: 30px;
      font-family: "Bliss Pro Extra Bold";
      font-size: 18px;
      line-height: 24px; }
      @media only screen and (max-width: 992px) {
        .header .bottom .modal {
          margin-left: 0;
          width: 100%;
          background-color: #460096;
          border-radius: 0; } }
      @media only screen and (max-width: 525px) {
        .header .bottom .modal {
          height: 40px;
          font-size: 12px;
          text-align: center;
          padding-left: 9px; } }
  .header .main {
    padding-top: 120px; }
    @media only screen and (max-width: 992px) {
      .header .main {
        padding-top: 70px; } }
    .header .main p {
      font-family: "Bliss Pro Light"; }
      .header .main p:first-child {
        font-size: 42px;
        line-height: 66px;
        color: #333333; }
        @media only screen and (max-width: 768px) {
          .header .main p:first-child {
            font-size: 40px; } }
        @media only screen and (max-width: 550px) {
          .header .main p:first-child {
            font-size: 21px;
            text-align: center;
            line-height: 40px; } }
      .header .main p:last-child {
        font-size: 30px;
        line-height: 66px; }
        .header .main p:last-child span {
          font-family: "Bliss Pro Black"; }
        @media only screen and (max-width: 550px) {
          .header .main p:last-child {
            font-size: 22px;
            line-height: 40px;
            text-align: center; } }
    .header .main h1 {
      margin-top: 15px;
      font-family: "Bliss Pro Black";
      font-size: 48px;
      line-height: 72px;
      color: #460096; }
      @media only screen and (max-width: 550px) {
        .header .main h1 {
          margin-top: 0;
          font-size: 30px;
          line-height: 40px;
          text-align: center; } }
      .header .main h1 span:first-child {
        display: inline-block;
        padding: 0 10px;
        font-size: 66px;
        line-height: 90px;
        color: #fff;
        background-color: #460096; }
        @media only screen and (max-width: 992px) {
          .header .main h1 span:first-child {
            font-size: 63px; } }
        @media only screen and (max-width: 768px) {
          .header .main h1 span:first-child {
            font-size: 46px; } }
        @media only screen and (max-width: 550px) {
          .header .main h1 span:first-child {
            font-size: 24px;
            line-height: 47px; } }
      .header .main h1 span:last-child {
        display: block;
        margin-top: 10px; }

.form {
  background-image: url(../img/texture.jpg);
  height: 290px;
  -webkit-box-shadow: 0px -7px 16px 0px rgba(0, 0, 0, 0.39);
  box-shadow: 0px -7px 16px 0px rgba(0, 0, 0, 0.39);
  position: relative; }
  @media only screen and (max-width: 576px) {
    .form {
      height: initial; } }
  .form::before {
    content: " ";
    display: block;
    width: 1284px;
    height: 140px;
    position: absolute;
    top: -140px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    background-image: url(../img/form-bg.png);
    background-size: cover; }
    @media only screen and (max-width: 768px) {
      .form::before {
        top: -105px; } }
    @media only screen and (max-width: 768px) {
      .form::before {
        top: -85px; } }
  .form__overlay {
    max-width: 930px;
    margin: 0 auto;
    position: relative;
    padding: 0 15px; }
    .form__overlay::before {
      content: " ";
      display: block;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      position: absolute;
      top: -95px;
      right: -140px;
      background-color: #460096;
      background-repeat: no-repeat;
      background-image: url(../img/pen.png);
      background-position: center center; }
      @media only screen and (max-width: 1199px) {
        .form__overlay::before {
          display: none; } }
  .form__content {
    position: relative;
    top: -35px; }
  .form__title {
    font-family: "Bliss Pro Black";
    font-size: 30px;
    color: #460096;
    text-align: center;
    text-transform: uppercase; }
    @media only screen and (max-width: 480px) {
      .form__title {
        font-size: 24px; } }
  .form ul {
    width: 440px;
    margin: 15px auto 0;
    font-family: "Bliss Pro Light";
    font-size: 21px;
    line-height: 30px;
    color: #333333; }
    @media only screen and (max-width: 480px) {
      .form ul {
        width: 100%;
        font-size: 17px;
        line-height: 21px; } }
    .form ul li {
      background-repeat: no-repeat;
      background-image: url(../img/points.png);
      background-position: left center;
      padding-left: 45px; }
      @media only screen and (max-width: 480px) {
        .form ul li {
          padding-left: 27px;
          background-size: 20px; } }
      .form ul li:not(:first-child) {
        margin-top: 10px; }
  .form form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 30px; }
    @media only screen and (max-width: 576px) {
      .form form {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    .form form input {
      display: block;
      width: calc(33.33334% - 10px);
      height: 67px;
      border-radius: 33.5px;
      font-size: 20px;
      line-height: 24px; }
      @media only screen and (max-width: 576px) {
        .form form input {
          width: 300px;
          max-width: 100%;
          margin: 0 auto; } }
      @media only screen and (max-width: 480px) {
        .form form input {
          height: 50px;
          font-size: 17px; } }
      @media only screen and (max-width: 576px) {
        .form form input:not(:first-child) {
          margin: 15px auto 0; } }
      .form form input:not([type="submit"]) {
        text-align: center;
        border: 1px solid #9b9b9b;
        font-family: "Bliss Pro Light"; }
      .form form input[type="submit"] {
        background-color: #0dc877;
        color: #fff;
        -webkit-transition: background-color .15s linear;
        -o-transition: background-color .15s linear;
        transition: background-color .15s linear;
        cursor: pointer;
        font-family: "Bliss Pro Extra Bold"; }
        .form form input[type="submit"]:hover {
          background-color: #04e080; }

.advantages {
  padding: 60px 0;
  background-image: url(../img/advantages-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Bliss Pro Bold";
  font-size: 24px;
  color: #fff; }
  @media only screen and (max-width: 480px) {
    .advantages {
      padding: 45px 0;
      font-size: 20px; } }
  .advantages__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 992px) {
      .advantages__row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .advantages__col {
    width: calc(25% - 15px);
    text-align: center; }
    @media only screen and (max-width: 992px) {
      .advantages__col {
        width: calc(50% - 15px); } }
    @media only screen and (max-width: 480px) {
      .advantages__col {
        width: 100%; } }
    @media only screen and (max-width: 992px) {
      .advantages__col:nth-child(3), .advantages__col:nth-child(4) {
        margin-top: 40px; } }
    @media only screen and (max-width: 480px) {
      .advantages__col:not(:first-child) {
        margin-top: 35px; } }
  .advantages__image-block {
    height: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media only screen and (max-width: 480px) {
      .advantages__image-block {
        height: 150px; } }
    .advantages__image-block img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
      @media only screen and (max-width: 480px) {
        .advantages__image-block img {
          max-height: 100%; } }
  .advantages p {
    margin-top: 25px; }

.choose {
  padding: 55px 0 100px; }
  .choose__buttons {
    height: 75px;
    border-radius: 37.5px;
    border: 1px solid #460096;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px; }
    @media only screen and (max-width: 480px) {
      .choose__buttons {
        height: 50px;
        border-radius: 25px;
        margin-top: 25px; } }
    .choose__buttons button {
      display: block;
      width: 33.33334%;
      background-color: #f3f5f8;
      font-size: 24px;
      font-family: "Bliss Pro Regular";
      cursor: pointer; }
      @media only screen and (max-width: 768px) {
        .choose__buttons button {
          font-size: 18px; } }
      @media only screen and (max-width: 480px) {
        .choose__buttons button {
          font-size: 13px; } }
      .choose__buttons button.active {
        background-color: #460096;
        font-family: "Bliss Pro Extra Bold";
        color: #fff; }
      .choose__buttons button:nth-child(2) {
        border-left: 1px solid #460096;
        border-right: 1px solid #460096; }
  .choose__row {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: none; }
    @media only screen and (max-width: 1199px) {
      .choose__row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    .choose__row.active {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
  .choose__col {
    width: 33.33334%;
    padding: 0 33px;
    text-align: center; }
    @media only screen and (max-width: 1199px) {
      .choose__col {
        width: 50%; } }
    @media only screen and (max-width: 768px) {
      .choose__col {
        width: 100%;
        padding: 0; } }
    .choose__col .choose__title {
      margin-top: 55px;
      font-family: "Bliss Pro Extra Bold";
      font-size: 30px;
      color: #460096; }
      @media only screen and (max-width: 480px) {
        .choose__col .choose__title {
          margin-top: 35px;
          font-size: 25px; } }
    .choose__col .image-block {
      margin-top: 40px;
      position: relative; }
      @media only screen and (max-width: 480px) {
        .choose__col .image-block {
          margin-top: 20px; } }
      .choose__col .image-block img {
        display: block;
        margin: 0 auto;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        -o-object-fit: cover;
           object-fit: cover; }
        @media only screen and (max-width: 348px) {
          .choose__col .image-block img {
            width: 280px;
            height: 280px; } }
      .choose__col .image-block .characteristics {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 140px;
        height: 40px;
        text-align: center;
        border: 1px solid #c2c2c2;
        border-radius: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        font-family: "Bliss Pro Extra Light";
        font-size: 16px;
        color: #333333;
        cursor: pointer; }
        @media only screen and (max-width: 576px) {
          .choose__col .image-block .characteristics {
            right: 10px; } }
        .choose__col .image-block .characteristics::after {
          content: " ";
          width: 56px;
          height: 54px;
          background-repeat: no-repeat;
          background-image: url(../img/characteristics.png);
          background-size: cover;
          position: absolute;
          top: -40px;
          right: -25px; }
    .choose__col .garancy .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .choose__col .garancy .row:first-child {
        margin-top: 25px;
        background-color: #f3f5f8;
        font-family: "Bliss Pro Extra Bold";
        font-size: 17px;
        color: #333;
        padding: 10px 0; }
        @media only screen and (max-width: 480px) {
          .choose__col .garancy .row:first-child {
            font-size: 16px; } }
        .choose__col .garancy .row:first-child .left {
          position: relative;
          padding-left: 15px; }
          .choose__col .garancy .row:first-child .left::after {
            content: " ";
            display: block;
            height: 26px;
            width: 1px;
            background-color: #460096;
            position: absolute;
            right: 0.5px;
            top: calc(50% - 13px); }
        .choose__col .garancy .row:first-child .right {
          padding-right: 10px; }
      .choose__col .garancy .row:nth-child(2) {
        margin-top: 5px;
        font-family: "Bliss Pro Black";
        font-size: 24px;
        color: #460096;
        padding: 0 20px; }
        @media only screen and (max-width: 480px) {
          .choose__col .garancy .row:nth-child(2) {
            font-size: 21px; } }
      .choose__col .garancy .row .left {
        width: 35%;
        text-align: left; }
      .choose__col .garancy .row .right {
        width: 65%;
        text-align: right; }
    .choose__col .choose__description {
      margin-top: 25px;
      font-family: "Bliss Pro Extra Light";
      font-size: 21px;
      line-height: 24px;
      color: #333;
      text-align: left;
      padding: 0 10px; }
      @media only screen and (max-width: 480px) {
        .choose__col .choose__description {
          font-size: 17px;
          line-height: 20px; } }
    .choose__col .modal {
      text-transform: uppercase;
      margin: 20px auto 0;
      width: 277px;
      height: 65px;
      border-radius: 32.5px;
      font-family: "Bliss Pro Extra Bold";
      font-size: 20px;
      line-height: 24px; }
      @media only screen and (max-width: 480px) {
        .choose__col .modal {
          height: 50px;
          border-radius: 25px;
          font-size: 17px;
          margin: 20px auto 0; } }

.how {
  padding: 55px 0 60px;
  background-image: url(../img/how-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  @media only screen and (max-width: 480px) {
    .how {
      padding: 40px 0 40px; } }
  @media only screen and (max-width: 1500px) {
    .how::before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      position: absolute;
      top: 0;
      left: 0; } }
  .how .container {
    position: relative; }
  .how .steps {
    width: 380px;
    margin-left: auto;
    margin-right: 76px;
    font-family: "Bliss Pro Light";
    font-size: 21px;
    line-height: 24px;
    color: #333; }
    @media only screen and (max-width: 1199px) {
      .how .steps {
        margin: 25px auto 0;
        -webkit-transform: translateX(95px);
            -ms-transform: translateX(95px);
                transform: translateX(95px); } }
    @media only screen and (max-width: 768px) {
      .how .steps {
        width: calc(100% - 155px); } }
    @media only screen and (max-width: 576px) {
      .how .steps {
        width: calc(100% - 96px);
        margin-left: 0;
        margin-right: auto; } }
    @media only screen and (max-width: 480px) {
      .how .steps {
        -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
                transform: translateX(0);
        margin: 25px 0 0;
        width: 100%; } }
    .how .steps li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .how .steps li:not(:first-child) {
        margin-top: 20px; }
      .how .steps li span {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #460096;
        margin-right: 25px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center; }
        @media only screen and (max-width: 576px) {
          .how .steps li span {
            width: 75px;
            height: 75px;
            background-size: 35px;
            margin-right: 0; } }
        .how .steps li span::before {
          content: " ";
          display: block;
          width: 116px;
          height: 60px;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
          left: -150px;
          background-repeat: no-repeat;
          background-size: cover; }
          @media only screen and (max-width: 576px) {
            .how .steps li span::before {
              width: 80px;
              height: 40px;
              left: -110px; } }
          @media only screen and (max-width: 480px) {
            .how .steps li span::before {
              display: none; } }
        .how .steps li span::after {
          content: " ";
          display: block;
          width: 32px;
          height: 2px;
          background-color: #460096;
          position: absolute;
          left: -32px;
          top: calc(50% - 1px); }
          @media only screen and (max-width: 576px) {
            .how .steps li span::after {
              width: 25px;
              left: -25px; } }
          @media only screen and (max-width: 480px) {
            .how .steps li span::after {
              display: none; } }
      .how .steps li:first-child span {
        background-image: url(../img/mail.png); }
        .how .steps li:first-child span::before {
          background-image: url(../img/01.png); }
      .how .steps li:nth-child(2) span {
        background-image: url(../img/engineer.png); }
        .how .steps li:nth-child(2) span::before {
          background-image: url(../img/02.png);
          width: 116px;
          left: -160px; }
          @media only screen and (max-width: 576px) {
            .how .steps li:nth-child(2) span::before {
              width: 80px;
              height: 40px;
              left: -118px; } }
      .how .steps li:nth-child(3) span {
        background-image: url(../img/pen.png); }
        .how .steps li:nth-child(3) span::before {
          background-image: url(../img/03.png);
          width: 116px;
          left: -160px; }
          @media only screen and (max-width: 576px) {
            .how .steps li:nth-child(3) span::before {
              width: 80px;
              height: 40px;
              left: -118px; } }
      .how .steps li:nth-child(4) span {
        background-image: url(../img/open-window.png); }
        .how .steps li:nth-child(4) span::before {
          background-image: url(../img/04.png);
          width: 116px;
          left: -160px; }
          @media only screen and (max-width: 576px) {
            .how .steps li:nth-child(4) span::before {
              width: 80px;
              height: 40px;
              left: -118px; } }
      .how .steps li p {
        width: calc(100% - 125px); }
        @media only screen and (max-width: 576px) {
          .how .steps li p {
            width: calc(100% - 85px); } }
  .how .modal {
    display: block;
    margin: 60px auto 0;
    width: 277px;
    height: 65px;
    border-radius: 32.5px;
    font-family: "Bliss Pro Extra Bold";
    font-size: 20px;
    line-height: 24px; }
    @media only screen and (max-width: 480px) {
      .how .modal {
        font-size: 17px;
        height: 50px;
        margin: 40px auto 0; } }

.best-works {
  padding: 60px 0; }
  @media only screen and (max-width: 480px) {
    .best-works {
      padding: 40px 0; } }
  .best-works .best__overlay {
    width: 1545px;
    margin: 60px auto 0; }
    @media only screen and (max-width: 1580px) {
      .best-works .best__overlay {
        width: 100%; } }
    @media only screen and (max-width: 480px) {
      .best-works .best__overlay {
        margin: 30px auto 0; } }
  .best-works .best__slider a {
    display: block;
    width: 505px;
    height: 345px;
    -o-object-fit: cover;
       object-fit: cover;
    margin: 0 5px; }
    .best-works .best__slider a img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; }
  .best-works .best__slider .slick-dots {
    bottom: -54px;
    z-index: 1; }
    .best-works .best__slider .slick-dots li {
      width: 10px;
      height: 10px;
      background-color: rgba(70, 0, 150, 0.5); }
      .best-works .best__slider .slick-dots li.slick-active {
        background-color: #460096; }
      .best-works .best__slider .slick-dots li button {
        opacity: 0; }
  .best-works .best__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
    top: -2px;
    cursor: pointer; }
    @media only screen and (max-width: 1580px) {
      .best-works .best__btns {
        padding: 0 5px; } }
    .best-works .best__btns .best__prev,
    .best-works .best__btns .best__next {
      width: 23px;
      height: 33px;
      background-size: cover;
      z-index: 2; }
    .best-works .best__btns .best__prev {
      background-image: url(../img/arrow-left.png); }
    .best-works .best__btns .best__next {
      background-image: url(../img/arrow-right.png); }

.reviews {
  padding: 0 0 50px; }
  .reviews__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 992px) {
      .reviews__row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .reviews__col {
    width: calc(50% - 10px); }
    @media only screen and (max-width: 992px) {
      .reviews__col {
        width: 100%; } }
  .reviews__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    padding: 27px 20px 35px 23px;
    background-color: #f3f5f8; }
    @media only screen and (max-width: 480px) {
      .reviews__item {
        padding: 30px 10px 10px 10px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
    .reviews__item-image-block {
      position: relative;
      width: 140px; }
      .reviews__item-image-block img {
        display: block;
        max-width: 100%;
        margin: 0 auto; }
      .reviews__item-image-block .quote {
        display: block;
        width: 70px;
        height: 67px;
        background-image: url(../img/quote.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        right: -30px;
        top: -20px; }
    .reviews__item .reviews__block {
      margin-left: 33px;
      color: #333;
      font-size: 20px;
      line-height: 24px;
      width: calc(100% - 173px); }
      @media only screen and (max-width: 480px) {
        .reviews__item .reviews__block {
          margin-top: 15px;
          width: 100%;
          margin-left: 0;
          font-size: 17px;
          line-height: 22px; } }
      .reviews__item .reviews__block .text {
        font-family: "Bliss Pro Extra Light"; }
      .reviews__item .reviews__block .author {
        margin-top: 7px;
        font-family: "Bliss Pro Extra Bold";
        text-align: right; }

.promo {
  height: 690px;
  background-image: url(../img/promo-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center; }
  @media only screen and (max-width: 480px) {
    .promo {
      height: 400px; } }
  .promo .container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .promo p:first-child {
    font-family: "Bliss Pro Light";
    font-size: 42px;
    line-height: 66px; }
    @media only screen and (max-width: 768px) {
      .promo p:first-child {
        font-size: 34px; } }
    @media only screen and (max-width: 480px) {
      .promo p:first-child {
        font-size: 21px;
        line-height: 30px; } }
  .promo p:nth-child(2) {
    margin-top: 15px;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 15px;
    background-color: #460096;
    font-family: "Bliss Pro Black";
    font-size: 60px;
    line-height: 90px; }
    @media only screen and (max-width: 768px) {
      .promo p:nth-child(2) {
        font-size: 38px; } }
    @media only screen and (max-width: 480px) {
      .promo p:nth-child(2) {
        font-size: 23px;
        line-height: 40px; } }
  .promo p:nth-child(3) {
    text-transform: uppercase;
    font-size: 42px;
    line-height: 72px;
    font-family: "Bliss Pro Black"; }
    @media only screen and (max-width: 480px) {
      .promo p:nth-child(3) {
        font-size: 30px;
        line-height: 50px; } }
  .promo p:nth-child(4) {
    font-family: "Bliss Pro Light";
    font-size: 30px;
    line-height: 66px; }
    @media only screen and (max-width: 480px) {
      .promo p:nth-child(4) {
        font-size: 24px;
        line-height: 40px; } }
    .promo p:nth-child(4) span {
      text-transform: uppercase;
      font-family: "Bliss Pro Black";
      padding: 10px;
      background-color: #460096; }

.security {
  padding: 50px 0; }
  .security__row {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 50px; }
    @media only screen and (max-width: 992px) {
      .security__row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .security__col {
    width: calc(25% - 10px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media only screen and (max-width: 992px) {
      .security__col {
        width: calc(50% - 10px); } }
    @media only screen and (max-width: 992px) {
      .security__col:nth-child(3), .security__col:nth-child(4) {
        margin-top: 25px; } }
    .security__col img {
      display: block;
      max-width: 100%; }
  @media only screen and (max-width: 1199px) {
    .security .certificate__slider .slick-track {
      margin: 0 -330px; } }
  @media only screen and (max-width: 992px) {
    .security .certificate__slider .slick-track {
      margin: 0 -340px; } }
  @media only screen and (max-width: 768px) {
    .security .certificate__slider .slick-track {
      margin: 0 -350px; } }
  .security .certificate__slider .certificate__slide {
    display: block;
    width: 320px;
    height: 465px;
    margin: 20px 30px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25); }
    @media only screen and (max-width: 1199px) {
      .security .certificate__slider .certificate__slide {
        margin: 20px 55px; } }
    @media only screen and (max-width: 992px) {
      .security .certificate__slider .certificate__slide {
        margin: 20px 15px; } }
    .security .certificate__slider .certificate__slide img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; }
  .security .certificate__slider .slick-dots {
    bottom: -54px; }
    .security .certificate__slider .slick-dots li {
      width: 10px;
      height: 10px;
      background-color: rgba(70, 0, 150, 0.5); }
      .security .certificate__slider .slick-dots li.slick-active {
        background-color: #460096; }
      .security .certificate__slider .slick-dots li button {
        opacity: 0; }
  .security .certificate__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
    top: -2px;
    z-index: -1;
    cursor: pointer; }
    .security .certificate__btns .certificate__prev,
    .security .certificate__btns .certificate__next {
      width: 23px;
      height: 33px;
      background-size: cover; }
    .security .certificate__btns .certificate__prev {
      background-image: url(../img/arrow-left.png); }
    .security .certificate__btns .certificate__next {
      background-image: url(../img/arrow-right.png); }

.workers {
  padding: 95px 0; }
  @media only screen and (max-width: 480px) {
    .workers {
      padding: 60px; } }
  .workers__row {
    margin-top: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 992px) {
      .workers__row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    @media only screen and (max-width: 480px) {
      .workers__row {
        margin-top: 40px; } }
  .workers__col {
    width: calc(25% - 10px);
    text-align: center; }
    @media only screen and (max-width: 992px) {
      .workers__col {
        width: calc(50% - 10px); } }
    @media only screen and (max-width: 480px) {
      .workers__col {
        width: 100%; } }
    @media only screen and (max-width: 480px) {
      .workers__col:nth-child(2) {
        margin-top: 35px; } }
    @media only screen and (max-width: 992px) {
      .workers__col:nth-child(3), .workers__col:nth-child(4) {
        margin-top: 35px; } }
    .workers__col .name {
      text-transform: uppercase;
      font-family: "Bliss Pro Black";
      font-size: 30px;
      line-height: 45px;
      margin-top: 15px;
      color: #460096; }
      @media only screen and (max-width: 480px) {
        .workers__col .name {
          font-size: 23px;
          line-height: 30px;
          margin-top: 10px; } }
    .workers__col .position {
      font-family: "Bliss Pro Extra Light";
      font-size: 20px;
      line-height: 27px;
      color: #333; }
      @media only screen and (max-width: 480px) {
        .workers__col .position {
          font-size: 17px;
          line-height: 24px; } }
  .workers__image-block {
    width: 196px;
    height: 196px;
    margin: 0 auto;
    position: relative; }
    .workers__image-block img {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; }
  .workers__call {
    position: absolute;
    bottom: 0;
    right: 9px;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0dc877;
    background-image: url(../img/phone.png);
    background-repeat: no-repeat;
    background-position: center center; }

.contact {
  background-image: url(../img/texture.jpg);
  position: relative;
  -webkit-box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.39); }
  .contact::after {
    content: " ";
    display: block;
    width: 1284px;
    height: 159px;
    position: absolute;
    bottom: -159px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    background-image: url(../img/contact-bg.png);
    background-size: cover; }
    @media only screen and (max-width: 480px) {
      .contact::after {
        bottom: -105px; } }
  .contact__overlay {
    max-width: 875px;
    margin: 0 auto;
    position: relative;
    bottom: -35px;
    z-index: 1;
    padding: 0 15px; }
  .contact ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 610px;
    margin: 35px auto 0; }
    .contact ul li a {
      display: block; }
      .contact ul li a img {
        display: block; }
  .contact__row {
    margin-top: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-family: "Bliss Pro Black";
    font-size: 24px;
    line-height: 30px;
    color: #333; }
    @media only screen and (max-width: 768px) {
      .contact__row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    @media only screen and (max-width: 480px) {
      .contact__row {
        font-size: 21px;
        line-height: 27px; } }
  @media only screen and (max-width: 768px) {
    .contact__col {
      width: 100%;
      text-align: center; } }
  .contact__col:first-child a {
    display: block; }
    .contact__col:first-child a:nth-child(2) {
      margin-top: 35px; }
  .contact__col:last-child a {
    display: block;
    margin-bottom: 35px; }

.map iframe {
  display: block;
  width: 100%;
  height: 500px; }

.footer {
  background-color: #222222;
  color: #fff;
  text-align: center;
  padding: 50px 0 60px; }
  @media only screen and (max-width: 480px) {
    .footer {
      padding: 35px 0 40px; } }
  .footer p:first-child {
    font-family: "Bliss Pro Black";
    font-size: 24px; }
    @media only screen and (max-width: 480px) {
      .footer p:first-child {
        font-size: 20px; } }
  .footer p:nth-child(2) {
    font-family: "Bliss Pro Light";
    font-size: 20px; }
    @media only screen and (max-width: 480px) {
      .footer p:nth-child(2) {
        font-size: 17px; } }
    .footer p:nth-child(2) a {
      color: #0dc877; }

.modal-content {
  background-color: #fff;
  padding: 15px;
  font-family: "Bliss Pro Light"; }
  @media only screen and (max-width: 480px) {
    .modal-content {
      padding: 10px; } }

.modal-overlay {
  background-color: rgba(70, 0, 150, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: none; }

.modal-title, .modal-description {
  color: #fff !important; }

.modal-block {
  width: 650px;
  max-width: 100%;
  padding: 15px;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .modal-block {
      width: 100%;
      padding: 15px 30px; } }
  @media only screen and (max-width: 480px) {
    .modal-block {
      padding: 10px; } }
  .modal-block .modal-close {
    position: absolute;
    top: -26px;
    right: -27px;
    cursor: pointer;
    width: 27px;
    height: 26px;
    background-size: cover;
    background-image: url(../img/close.png); }
    @media only screen and (max-width: 768px) {
      .modal-block .modal-close {
        top: 0;
        right: 0; } }
    @media only screen and (max-width: 480px) {
      .modal-block .modal-close {
        top: -20px;
        right: 10px; } }
  .modal-block form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 30px; }
    @media only screen and (max-width: 576px) {
      .modal-block form {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    .modal-block form input {
      display: block;
      width: calc(33.33334% - 10px);
      height: 67px;
      border-radius: 33.5px;
      font-size: 20px;
      line-height: 24px;
      color: #fff; }
      @media only screen and (max-width: 576px) {
        .modal-block form input {
          width: 300px;
          max-width: 100%;
          margin: 0 auto; } }
      @media only screen and (max-width: 480px) {
        .modal-block form input {
          height: 50px;
          font-size: 17px; } }
      @media only screen and (max-width: 576px) {
        .modal-block form input:not(:first-child) {
          margin: 15px auto 0; } }
      .modal-block form input:not([type="submit"]) {
        text-align: center;
        border: 1px solid #fff;
        font-family: "Bliss Pro Light"; }
      .modal-block form input[type="submit"] {
        width: 300px;
        background-color: #0dc877;
        color: #fff;
        -webkit-transition: background-color .15s linear;
        -o-transition: background-color .15s linear;
        transition: background-color .15s linear;
        cursor: pointer;
        font-family: "Bliss Pro Extra Bold"; }
        @media only screen and (max-width: 463px) {
          .modal-block form input[type="submit"] {
            width: 220px; } }
        .modal-block form input[type="submit"]:hover {
          background-color: #04e080; }

.swal-button {
  background-color: #0dc877; }

.swal-button:not([disabled]):hover {
  background-color: #04e080; }

.swal-title {
  font-family: "Bliss Pro Black"; }

.swal-text {
  font-family: "Bliss Pro Extra Light"; }

html {
  overflow: hidden;
  overflow-y: auto; }

body {
  overflow-x: hidden; }
  body.overflow {
    overflow: hidden; }

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }
  @media only screen and (max-width: 1200px) {
    .container {
      max-width: 960px; } }
  @media only screen and (max-width: 992px) {
    .container {
      max-width: 720px; } }
  @media only screen and (max-width: 768px) {
    .container {
      max-width: 540px; } }
  @media only screen and (max-width: 576px) {
    .container {
      max-width: none; } }

.modal {
  display: block;
  background-color: #0dc877;
  color: #fff;
  cursor: pointer;
  -webkit-transition: background-color .15s linear;
  -o-transition: background-color .15s linear;
  transition: background-color .15s linear; }
  .modal:hover {
    background-color: #04e080; }

.section__title {
  text-transform: uppercase;
  font-family: "Bliss Pro Black";
  font-size: 48px;
  line-height: 72px;
  color: #460096;
  text-align: center; }
  @media only screen and (max-width: 992px) {
    .section__title {
      font-size: 40px;
      line-height: 55px; } }
  @media only screen and (max-width: 768px) {
    .section__title {
      font-size: 32px;
      line-height: 43px; } }
  @media only screen and (max-width: 480px) {
    .section__title {
      font-size: 25px;
      line-height: 33px; } }

.section__description {
  font-family: "Bliss Pro Extra Light";
  font-size: 27px;
  line-height: 48px;
  color: #333333;
  text-align: center; }
  @media only screen and (max-width: 992px) {
    .section__description {
      font-size: 25px;
      line-height: 33px; } }
  @media only screen and (max-width: 768px) {
    .section__description {
      font-size: 23px;
      line-height: 30px; } }
  @media only screen and (max-width: 480px) {
    .section__description {
      font-size: 18px;
      line-height: 24px; } }
