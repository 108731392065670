@font-face {
    font-family: 'Bliss Pro Regular';
    src: url('../fonts/BlissPro.eot');
    src: url('../fonts/BlissPro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BlissPro.woff') format('woff'),
    url('../fonts/BlissPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bliss Pro Black';
    src: url('../fonts/BlissPro-Heavy.eot');
    src: url('../fonts/BlissPro-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BlissPro-Heavy.woff') format('woff'),
    url('../fonts/BlissPro-Heavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Light';
    src: url('../fonts/Gilroy-Light.eot');
    src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Light.woff') format('woff'),
    url('../fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Play Regular';
    src: url('../fonts/Play.eot');
    src: url('../fonts/Play.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Play.woff') format('woff'),
    url('../fonts/Play.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bliss Pro Medium';
    src: url('../fonts/BlissPro-Medium.eot');
    src: url('../fonts/BlissPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BlissPro-Medium.woff') format('woff'),
    url('../fonts/BlissPro-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bliss Pro Light';
    src: url('../fonts/BlissPro-Light.eot');
    src: url('../fonts/BlissPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BlissPro-Light.woff') format('woff'),
    url('../fonts/BlissPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bliss Pro Extra Bold';
    src: url('../fonts/BlissPro-ExtraBold.eot');
    src: url('../fonts/BlissPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BlissPro-ExtraBold.woff') format('woff'),
    url('../fonts/BlissPro-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bliss Pro Bold';
    src: url('../fonts/BlissPro-Bold.eot');
    src: url('../fonts/BlissPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BlissPro-Bold.woff') format('woff'),
    url('../fonts/BlissPro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bliss Pro Extra Light';
    src: url('../fonts/BlissPro-ExtraLight.eot');
    src: url('../fonts/BlissPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BlissPro-ExtraLight.woff') format('woff'),
    url('../fonts/BlissPro-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}