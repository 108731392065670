@import 'normalize';
@import 'responce';

@import 'variebles';

@import 'fonts';
@import 'header';
@import 'home';
@import 'footer';

html {
    overflow: hidden;
    overflow-y: auto;
}

body {
    overflow-x: hidden;
    &.overflow {
        overflow: hidden;
    }
}

.container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    @include mediaw(1200px){
        max-width: 960px;
    }
    @include mediaw(992px){
        max-width: 720px;
    }
    @include mediaw(768px){
        max-width: 540px;
    }
    @include mediaw(576px){
        max-width: none;
    }
}

.modal {
    display: block;
    background-color: $colorGreen;
    color: #fff;
    cursor: pointer;
    transition: background-color .15s linear;
    &:hover {
        background-color: #04e080;
    }
}

.section__title {
    text-transform: uppercase;
    font-family: $fontBlissHeavy;
    font-size: 48px;
    line-height: 72px;
    color: $colorPurple;
    text-align: center;
    @include mediaw(992px) {
        font-size: 40px;
        line-height: 55px;
    }
    @include mediaw(768px) {
        font-size: 32px;
        line-height: 43px;
    }
    @include mediaw(480px) {
        font-size: 25px;
        line-height: 33px;
    }
}

.section__description {
    font-family: $fontBlissExtraLight;
    font-size: 27px;
    line-height: 48px;
    color: #333333;
    text-align: center;
    @include mediaw(992px) {
        font-size: 25px;
        line-height: 33px;
    }
    @include mediaw(768px) {
        font-size: 23px;
        line-height: 30px;
    }
    @include mediaw(480px) {
        font-size: 18px;
        line-height: 24px;
    }
}