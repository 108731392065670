.header {
    background-image: url(../img/header-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 825px;
    @include mediaw(550px) {
        min-height: 660px;
    }
    .top {
        background-color: #460096;
        color: #fff;
        padding: 10px 0;
        font-family: $fontBlissMedium;
        font-size: 16px;
        @include mediaw(480px) {
            padding: 5px 0;
        }
        .container {
            display: flex;
            justify-content: space-between;
        }
        .lang {
            a {
                display: inline-block;
                &:first-child {
                    margin-right: 10px;
                }
                &.active {
                    color: #999999;
                }
            }
        }
        ul {
            display: flex;
            li:not(:last-child) {
                margin-right: 50px;
            }
        }
        .container {
            display: flex;
            align-items: center;
        }
        .menu {
            @include mediaw(992px) {
                display: none;
            }
        }
        .mobile-social {
            display: none;
            @include mediaw(992px) {
                display: block;
            }
            ul {
                li {
                    a {
                        display: block;
                        max-width: 40px;
                        img {
                            display: block;
                            max-width: 100%;
                        }
                    }
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
    .bottom {
        background-color: rgba(236, 230, 242, 0.5);
        padding: 13px 0;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include mediaw(992px) {
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                padding: 0;
                max-width: 100%;
            }
        }
        .left {
            display: flex;
            align-items: center;
        }
        .logo {
            margin-right: 70px;
            @include mediaw(992px) {
                width: 100%;
            }
            a {
                display: block;
                img {
                    margin: 0 auto;
                }
            }
        }
        .social {
            a {
                &:first-child {
                    margin-right: 5px;
                }
            }
            @include mediaw(992px) {
                display: none;
            }
        }
        .right {
            display: flex;
            @include mediaw(992px) {
                margin-top: 15px;
                justify-content: space-between;
                width: 100%;
            }
        }
        .consultation {
            font-family: $fontBlissLight;
            font-size: 16px;
            color: #000002;
            padding-left: 65px;
            background-image: url(../img/headset.png);
            background-repeat: no-repeat;
            background-position: left center;
            @include mediaw(992px) {
                background: none;
                padding-left: 15px;
            }
            @include mediaw(525px) {
                font-size: 12px;
            }
            @include mediaw(340px) {
                transform: translateX(-11px);
            }
            a {
                font-family: $fontBlissExtraBold;
                font-size: 24px;
                @include mediaw(525px) {
                    font-size: 17px;
                }
            }
        }
        .modal-wrapper {
            @include mediaw(992px) {
                width: 50%;
                border-radius: 0;
                position: relative;
            }
            a {
                @include mediaw(992px) {
                    display: block;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-image: url(../img/ringer-white.png);
                    background-repeat: no-repeat;
                    background-size: 40px;
                    background-position: center center;
                    position: absolute;
                    left: -40px;
                    top: calc(50% + -40px);
                    background-color: $colorGreen;
                    @include mediaw(525px) {
                        width: 56px;
                        height: 56px;
                        left: -28px;
                        top: calc(50% + -28px);
                    }
                }
                @include mediaw(525px) {
                    left: -28px;
                    top: calc(50% + -28px);
                }
            }
        }
        .modal {
            margin-left: 20px;
            width: 217px;
            height: 60px;
            border-radius: 30px;
            font-family: $fontBlissExtraBold;
            font-size: 18px;
            line-height: 24px;
            @include mediaw(992px) {
                margin-left: 0;
                width: 100%;
                background-color: $colorPurple;
                border-radius: 0;
            }
            @include mediaw(525px) {
                height: 40px;
                font-size: 12px;
                text-align: center;
                padding-left: 9px;
            }
        }
    }
    .main {
        padding-top: 120px;
        @include mediaw(992px) {    
            padding-top: 70px;
        }
        p {
            font-family: $fontBlissLight;
            &:first-child {
                font-size: 42px;
                line-height: 66px;
                color: #333333;
                @include mediaw(768px) {
                    font-size: 40px;
                }
                @include mediaw(550px) {
                    font-size: 21px;
                    text-align: center;
                    line-height: 40px;
                }
            }
            &:last-child {
                font-size: 30px;
                line-height: 66px;
                span {
                    font-family: $fontBlissHeavy;
                }
                @include mediaw(550px) {
                    font-size: 22px;
                    line-height: 40px;
                    text-align: center;
                }
            }
        }
        h1 {
            margin-top: 15px;
            font-family: $fontBlissHeavy;
            font-size: 48px;
            line-height: 72px;
            color: $colorPurple;
            @include mediaw(550px) {
                margin-top: 0;
                font-size: 30px;
                line-height: 40px;
                text-align: center;
            }
            span:first-child {
                display: inline-block;
                padding: 0 10px;
                font-size: 66px;
                line-height: 90px;
                color: #fff;
                background-color: $colorPurple;
                @include mediaw(992px) {
                    font-size: 63px;
                }
                @include mediaw(768px) {
                    font-size: 46px;
                }
                @include mediaw(550px) {
                    font-size: 24px;
                    line-height: 47px;
                }
            }
            span:last-child {
                display: block;
                margin-top: 10px;
            }
        }
    }
}